.button {
    gap: 1.5em;
    width: 100%;
    padding: 1em;
    border-radius: 10px 25px 10px 10px;  
}

.title {
    font-size: 1.5em;
    color: rgba(var(--brand-dark-rgb), 0.8);
    font-style: normal;
    font-weight: 700;
    line-height: normal;    
}

.icon {
    width: 138px;
    height: 75px;
}

@media screen and (min-width: 430px) {
    .button {
        gap: .75em;
    }

    .title {
        font-size: 1.8em;
    }   
    
    .icon {
        width: 170px;
        height: 92px;
    }        
}