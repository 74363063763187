.wallets {
    display: flex;
    gap: 1em;
    width: 100%;
    flex-direction: column;
    padding: 1em;

    border: var(--test-border-dotted);
    border-color: red;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

@media (min-width: 1024px) { 
    .wallets {
        padding: 2em 1em;
    } 
}