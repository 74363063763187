.button {
    width: 80%;
    gap: .5em;
    padding: 1em;
    border-radius: 25px 10px 10px 10px;
}

.icon {
    width: 80px;
    height: 80px;
}

.text {
    color: rgba(var(--brand-dark-rgb), 0.65);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.title {
    font-size: 1.25em;
}

.subtitle {
    font-size: 1em;
}

@media screen and (min-width: 430px) {
    .button {
        gap: .5em;
    }

    .title{
        font-size: 1.5em;
    }       
}