.invoices {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 1em;
    padding-bottom: 2em;
}

.noItems {
    width: 100%;
    text-align: center;
    padding-top: 2em;
}

.noItems p {
    margin-top: 2em;
    font-size: 1.25em;
    font-weight: 700;
    color: rgba(var(--brand-light-rgb), .35);
}

.noItemsIcon {
    fill: rgba(var(--brand-light-rgb), .35);
}

.invoice {
    color: var(--brand-text);
    padding: 20px;
    border-radius: 1em;
    background: #fff;
    border: 3px solid rgba(var(--brand-dark-rgb), 0.15);
    transition: all 0.2s ease;
}

.section {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    padding-bottom: .75em;
}

.company {
    font-weight: 400;
    font-size: 1.25em;
}

.amount {
    font-size: 1.5em;
    font-weight: 600;
    color: var(--brand-dark);
}

.date {
    color: rgba(var(--brand-dark-rgb), .55);
}

.text {
    font-size: .15em;
    color: rgba(var(--brand-dark-rgb), .55);
    font-weight: 400;
    background-color: var(--brand-light);
    padding: 1em;
    border: 0;
    border-radius: 1em;
    margin: 1em 0;
}

.text > span {
    display: block;
    color: var(--brand-dark);
    padding-top: .5em;
    font-size: 1.2em;
    font-weight: 600;
}

.actions {
    display: flex;
    justify-content: space-between;
    padding-top: 1em;
}

@media screen and (min-width: 430px) {
    .company {
        font-size: 1.65em;
    }
    
    .amount {
        font-size: 2.25em;
    }
    
    .text {
        font-size: 1em;
    }    
}


@media screen and (min-width: 1024px) {
    .invoice {
        background: rgba(255, 255, 255, .5);
    }

    .noItemsIcon {
        fill: rgba(var(--brand-dark-rgb), .65);
    }

    .noItems p {
        color: rgba(var(--brand-dark-rgb), .65);
    }
}